// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-sort-header-container {
  align-items: center;
}

.clickable{
  cursor: pointer;
}

tr:hover {
  background: #edf3fc !important;
}
.mat-elevation-z8 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 
              0px 3px 4px 0px rgba(0, 0, 0, 0.14), 
              0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.dropdown-wrapper {
  position: relative;
}

.clear-icon {
  position: absolute;
  top: 73%;
  right: 26px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 18px;
  color: #fff;  /* White color for the X mark */
  background-color: #aaa;  /* Light gray background for the circle */
  border-radius: 50%;  /* Makes the background a circle */
  width: 22px;  /* Width of the circle */
  height: 22px;  /* Height of the circle */
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.clear-icon:hover {
  background-color: #000;  /* Darker background color when hovered */
  color: #fff;  /* Keep the X mark color white when hovered */
}

`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
